import * as React from 'react';
import {Helmet} from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';

const Root = styled.div`
  padding: 20px;
  display: grid;
  gap: 40px;
  justify-content: center;
`;

const Image = styled.svg`
  width: 100%;
  max-width: 400px;
  height: auto;
`;

const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <title>Not found</title>
      </Helmet>

      <Root>
        <Image
          xmlns="http://www.w3.org/2000/svg"
          width="525"
          height="436"
          fill="none"
          viewBox="0 0 1050 873"
        >
          <path
            fill="#E5007C"
            d="M637.2 817.88c188.92 0 342.07-153.15 342.07-342.07S826.12 133.74 637.2 133.74 295.13 286.89 295.13 475.81 448.28 817.88 637.2 817.88z"
          />
          <path
            fill="#F39100"
            d="M232.25 629.64c128.141 0 232.02-103.879 232.02-232.02 0-128.141-103.879-232.02-232.02-232.02C104.109 165.6.23 269.479.23 397.62c0 128.141 103.879 232.02 232.02 232.02z"
          />
          <path
            fill="#941680"
            d="M461.57 362.27c-7.84-50.88-31.67-95.32-65.57-129.04-74.77 74.47-114.18 182.41-96.87 294.7 4.52 29.34 12.65 57.23 23.86 83.29 95.46-40.47 155.1-141.79 138.58-248.95z"
          />
          <path
            fill="#F39100"
            d="M689.6 200.76c55.212 0 99.97-44.758 99.97-99.97 0-55.212-44.758-99.97-99.97-99.97-55.212 0-99.97 44.758-99.97 99.97 0 55.212 44.758 99.97 99.97 99.97z"
          />
          <path
            fill="#941680"
            d="M596.13 136.22c8.15 21.52 23.71 40.35 45.48 52.26 44.32 24.25 98.94 11.43 128.21-28.03-53.17-22.42-112.64-31.65-173.69-24.23z"
          />
          <path
            fill="#4B074A"
            d="M966.27 245.45s-25.73-10.41-37.98 10.01c-12.26 20.43-70.54 104.71-70.54 104.71s43.02 39.83 55.72 0c12.7-39.83 52.8-114.72 52.8-114.72z"
          />
          <path
            fill="#F39100"
            d="M433.37 378.3c-9.76-91.18 42.71-156.19 109.57-163.34 66.86-7.15 131.87 45.28 141.63 136.47 9.75 91.15-42.7 156.19-109.55 163.34-66.85 7.15-131.9-45.32-141.65-136.47zm174.22-18.64c-5.85-54.7-32.77-76.41-57.06-73.81-24.31 2.6-46.02 29.52-40.17 84.21 5.85 54.7 32.76 76.37 57.08 73.77 24.28-2.59 46-29.47 40.15-84.17z"
          />
          <path
            fill="#E5007C"
            d="M144.48 834.81s-7.31 25.5-4.74 31.14c2.57 5.64 63.92 9.53 63.83 3.73-.09-5.8-21.97-11.23-28.35-16-6.38-4.77-2.5-13.03-2.5-13.03l-28.24-5.84zm152.5 8.11s11.49 29.44 17.99 29.63c6.5.19 53.96-24.65 51.2-29.7-2.77-5.05-35.6-.51-37.98-1.09-2.38-.58-18.36-10.63-18.36-10.63l-12.85 11.79z"
          />
          <path
            fill="#4B074A"
            d="M204.06 464.44s54.39 183.76 60.35 202.75c5.96 18.99 53.71 160.73 54.03 164.39.32 3.66-20.12 20.31-24.5 19.81-4.38-.5-87.81-118.75-89.78-143.42-1.97-24.67-54.4-215.84-52.74-235.11 1.67-19.27 52.64-8.42 52.64-8.42z"
          />
          <path
            fill="#941680"
            d="M93.91 487.17s2.65 13.23 11.42 36.96c6.15 16.64 58.89 122.2 60.17 140.01 1.28 17.8-29.67 151.45-28.54 165.81 1.12 14.35 31.22 21.24 39.42 14.66 4.97-3.98 56.05-148.99 54.1-189.96-1.95-40.97-50.33-185.27-50.33-185.27l-86.24 17.79z"
          />
          <path
            fill="#E5007C"
            d="M116.25 300.67c12.73-3.87 37.21-1.66 52.94 22.79 15.73 24.45 40.07 152.78 40.07 152.78S127.74 507.3 90.9 488.26c-13.96-54.94-36.98-168.63 25.35-187.59z"
          />
          <path
            fill="#F8C6C0"
            d="M117.31 313.39c14.63 0 26.49-11.86 26.49-26.49 0-14.63-11.86-26.49-26.49-26.49-14.63 0-26.49 11.86-26.49 26.49 0 14.63 11.86 26.49 26.49 26.49z"
          />
          <path
            fill="#181817"
            d="M79.66 265.4c-4.24 14.46 12.83 42.5 17.34 38.3 5.61-5.23-7.97-5.57-4.67-13.55 1.17-2.83 7.26-.27 8.92-1.58 3.88-3.07.8-10.35 3.68-13.22 8.67-8.65 25.52-2.83 31.46-7.97 4.51-3.9 5.46-19.64-1.48-25.29-5.75-4.68-13.57-4.24-18.22-2.65-4.65 1.59-9.7 8.93-14.9 10.6-5.2 1.67-19.99 8.06-22.13 15.36z"
          />
          <path
            fill="#C00216"
            d="M952 807.06s9.19 24.88 7.05 30.7c-2.14 5.82-63.03 14.26-63.38 8.47-.34-5.79 21.07-12.83 27.08-18.07 6.01-5.24 7.83-21.2 7.83-21.2l21.42.1zm90.06-9.2s9.4 31.55 6.76 37.16c-2.64 5.61-64.04 8.7-63.87 2.9.16-5.8 22.11-10.94 28.56-15.63 6.45-4.69 4.37-21.41 4.37-21.41l24.18-3.02z"
          />
          <path
            fill="#4B074A"
            d="M899.13 388.05S880.66 538 881.83 564.48c1.17 26.48 38.26 233.89 43.19 239.89s27.17 5.01 28.61 2.46c1.44-2.55-12.34-195.86-11.1-200.47 1.23-4.62 16.96-43.45 16.96-43.45l17.29-186.7-77.65 11.84z"
          />
          <path
            fill="#941680"
            d="M954.84 394.35s-16.25 171.41-13.96 201.9c4.37 58.3 68.24 205.29 71.89 210.06 6.31 8.23 25.74 4.54 31.07-4.4 5.32-8.94-36.02-180.61-33.64-204.35 2.38-23.74 22.64-118.56 17.12-178.64-5.52-60.08-72.48-24.57-72.48-24.57z"
          />
          <path
            fill="#F39100"
            d="M964.75 227.46c-15.15.05-31.95 17.57-41.64 37.22-14.51 29.42-27.49 127.93-27.47 159.05 0 4.05.11 6.45.34 6.71 1.92 2.3 25.74 22.18 134.21 8.06 7.89-1.02 25.17-211.33-65.44-211.04z"
          />
          <path
            fill="#F8C6C0"
            d="M957.43 234.52c15.536 0 28.13-12.594 28.13-28.13s-12.594-28.13-28.13-28.13-28.13 12.594-28.13 28.13 12.594 28.13 28.13 28.13z"
          />
          <path
            fill="#F39100"
            d="M942.95 166.82s-16 15.85-10.52 24.29 22.61 5.22 27.24 6.38c8.34 2.09 6.5 9.94 14.45 10.38 2.84.16 4.69-4.16 6.79-.56 2.1 3.6-2.55 11.44-.78 13.09 3.69 3.43 17.38-18.58 4.2-35.43-14.78-18.9-32.11-8.52-35.8-11.44-3.68-2.93-3.66-6.6-5.58-6.71z"
          />
          <path
            fill="#E5007C"
            d="M960.62 171.62c4.58.38 7.54 1.3 12.49 6.33 3.66 3.71 5.48 10.99 6.18 14.54 4.45.89 7.83 5.29 7.83 10.6 0 5.95-4.24 10.77-9.48 10.77s-9.48-4.82-9.48-10.77c0-3.96 1.89-7.41 4.69-9.29v-.01c-.48-5.08-5.53-13.29-8.08-15.53-5.71-5.03-10.04-3.91-12.87-4.11 1.81-1.75 4.4-2.88 8.72-2.53z"
          />
          <path
            fill="#941680"
            d="M826.62 426.59l-136.76 26.3-16.28-84.67 95.11-196.12 91.17-17.53 35.06 182.35 32.56-6.26 14.19 73.82-32.56 6.26 9.18 47.76-82.5 15.86-9.17-47.77zm-14.19-73.82l-16.28-84.67-46.66 96.77 62.94-12.1z"
          />
          <path
            fill="#F8C6C0"
            d="M838.41 342.39s-20.96-28.99-28.96-26.07c-7.99 2.92-8.85 15.53 1.24 22.52 8.32 5.76 21.89 8.02 21.89 8.02l5.83-4.47z"
          />
          <path
            fill="#4B074A"
            d="M980.2 234.53c-17.22-.94-23.08 33.54-25.42 52.85-2.34 19.3-9 58.88-23.42 67.24-14.42 8.36-71.9-10.5-94.28-22.21-6.35-3.17-12.23 13.2-9.62 16.26 3.12 3.66 102.12 67.74 140.74 30.28 38.61-37.47 41.66-142.8 12-144.42z"
          />
          <path
            fill="#C00216"
            d="M265.66 443.08l-124.85-11.84 7.33-77.29 131.77-145.47 83.23 7.89-15.78 166.46 29.72 2.82-6.39 67.39-29.72-2.82-4.13 43.6-75.31-7.14 4.13-43.6zm6.39-67.38l7.33-77.29-64.79 71.85 57.46 5.44z"
          />
          <path
            fill="#F8C6C0"
            d="M276.15 478.36s25.19 1.31 26.05 9.22c.86 7.91-19.01 21.81-34.25 2.28-15.23-19.54 8.2-11.5 8.2-11.5z"
          />
          <path
            fill="#4B074A"
            d="M108.52 367.36c15.52 21.02 34.33 43.13 61.1 68.11 8.55 7.98 72.48 50.14 93.18 55.12 5.2 1.25 14.44-12.78 12.38-17.46-2.05-4.68-49.94-32.11-80.72-68.7-23.04-27.39-48.16-70.09-64.85-73.53-19.7-4.07-38.07 13.46-21.09 36.46z"
          />
          <path
            fill="#F8C6C0"
            d="M799.49 431.81l21.79-4.19s.51 8.08-8.05 9.08c-7.68.9-13.74-4.89-13.74-4.89z"
          />
          <path
            fill="#941680"
            d="M589.31 589.99l22.45 95.46s36.01 17.19 41.12 11.74c5.11-5.45-17.58-107.2-61.99-106.87-1.03.01-1.58-.33-1.58-.33z"
          />
          <path
            fill="#181817"
            d="M558.78 529.04c9.89-8.59 46.99-6.17 51.93 12.66 4.94 18.84-1.48 24.25.29 29.61 1.78 5.37 12 24.45 9.49 33.88-3.63 13.63-26.85 17.56-36.67 12.62-9.83-4.93-19.99-10.59-25.24-10.16-5.25.43-26.32.35-31.94-13.8-5.62-14.15 12.15-22.93 14.48-31.34 6.91-24.86 13.82-30.13 17.66-33.47z"
          />
          <path
            fill="#F8C6C0"
            d="M486.73 485.1c5.64 2.48 13.91 18.05 13.91 18.05s-13.04 19.71-15.37 22.32c-.96 1.07-6.07.15-5.61-3.21.66-4.81 6.8-16.3 5.31-18.61-2.54-3.92-6.98-22.39 1.76-18.55z"
          />
          <path
            fill="#4B074A"
            d="M558.97 614.81c-2.26-22.76-68.7-24.08-75.45-34.18-6.74-10.09 1.18-43.69 8.65-60.51 2.01-4.77-10.16-8.27-12.27-6.24-2.52 2.44-46.3 70.19-17.28 96.52 29.03 26.34 99.11 32.27 96.35 4.41z"
          />
          <path
            fill="#F39100"
            d="M572.04 580.71c11.79-1.63 29.19 5.86 36.8 20.42 11.4 21.8 17.48 101.99 17.44 125.07 0 3.01-.09 4.78-.27 4.98-1.51 1.7-20.25 16.46-105.56 6.05-6.2-.75-14.49-147.37 51.59-156.52z"
          />
          <path
            fill="#F8C6C0"
            d="M575.12 594.24c15.9 0 28.79-12.89 28.79-28.79 0-15.9-12.89-28.79-28.79-28.79-15.9 0-28.79 12.89-28.79 28.79 0 15.9 12.89 28.79 28.79 28.79z"
          />
          <path
            fill="#181817"
            d="M551.55 548.9s5.4-8.13 12.08-6.77c8.11 1.65 16.18 22.75 19.49 24.1 3.31 1.35 12.44-4.17 14.28 5.4.96 4.99-5.73 7.97-8.62 9.23-1.82.8-14.58 12.85-13.36 24.39.61 5.78 3.91 13.02 14.88 14.04 11.03 1.03 24.37-5.72 24.37-5.72.08-.62-7.31-55.89-14.64-70.09-5.68-11.01-13.12-12.48-18.6-11.87-8.17.92-17.94-11-31.36 9.84-3.16 4.89 1.48 7.45 1.48 7.45z"
          />
          <path
            fill="#C00216"
            d="M652.34 784.18H490.3c-3.45 0-6.24-2.79-6.24-6.24V674.48c0-3.45 2.79-6.24 6.24-6.24h162.05c3.45 0 6.24 2.79 6.24 6.24v103.46c0 3.44-2.8 6.24-6.25 6.24z"
          />
          <path
            fill="#181817"
            d="M571.32 736.6c5.738 0 10.39-4.652 10.39-10.39s-4.652-10.39-10.39-10.39-10.39 4.652-10.39 10.39 4.652 10.39 10.39 10.39z"
          />
          <path
            fill="#F8C6C0"
            d="M649.22 818.98s27.3 4.75 27.3 6.44c0 1.7-2.54 12.38-2.54 12.38l-30.69-6.1 5.93-12.72z"
          />
          <path
            fill="#F39100"
            d="M658 820.6s32.19-.55 38.53 1.83c6.33 2.37 17.86 7.12 16.96 16.05-.9 8.93-17.24 9.16-27.44 8.31-10.2-.85-32.45-13.04-32.45-13.04s9.48-3.68 4.4-13.15z"
          />
          <path
            fill="#4B074A"
            d="M651.42 814.4c1.37 4.82 1.02 18.65-5.43 21.03-6.44 2.37-114.96-14.92-133.27-17.3-18.31-2.37-101.06-26.45-95.63-56.97 5.43-30.52 76.98-17.65 111.23-1.7 24.1 11.23 122.21 51.81 123.1 54.94z"
          />
          <path
            fill="#F8C6C0"
            d="M498.35 818.98s-27.3 4.75-27.3 6.44c0 1.7 2.54 12.38 2.54 12.38l30.69-6.1-5.93-12.72z"
          />
          <path
            fill="#F39100"
            d="M489.57 820.6s-32.19-.55-38.53 1.83c-6.33 2.37-17.86 7.12-16.96 16.05.9 8.93 17.24 9.16 27.44 8.31 10.2-.85 32.45-13.04 32.45-13.04s-9.91-4.38-4.4-13.15z"
          />
          <path
            fill="#941680"
            d="M496.15 814.4c-1.37 4.82-1.02 18.65 5.43 21.03 6.44 2.37 114.96-14.92 133.27-17.3 18.31-2.37 101.06-26.45 95.63-56.97-5.43-30.52-76.98-17.65-111.23-1.7-24.1 11.23-122.21 51.81-123.1 54.94z"
          />
          <path
            fill="#F8C6C0"
            d="M658.63 704.06s6.57 3.49 6.23 10.38c-.34 6.89-6.38 16.34-19.23 12.09-12.86-4.26-4.46-15.16 13-22.47z"
          />
        </Image>

        <a href="/">Home</a>
      </Root>
    </>
  );
};

export default NotFoundPage;
